import { Box } from '@mui/material';
import axios from 'axios';
import React, { useContext, useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { EditorContext } from '../apps/App';
import CenteredContent from '../components/CenteredContent';
import { Challenge } from '../components/Challenge/Challenge';
import Loader from '../components/Loader/Loader';
import { API_ADDRESS, RUNNER_ADDRESS } from '../constants/path';
import { SocketContext } from '../context/socketContext';
import { useCheckSession } from '../hooks/useCheckSession';
import {
  Challenge as IChallenge,
  CreateChallenge,
} from '../interfaces/Challenge';

export const ChallengePage = () => {
  const { language, challengeName, sessionId } = useParams() as {
    sessionId: string;
    language: string;
    challengeName: string;
  };

  const { setChallenge, setCode, setSubmitted } = useContext(EditorContext);
  const [loadingChallenge, setLoadingChallenge] = useState(false);
  const [challengeError, setChallengeError] = useState(false);
  const { sessionError, loading: loadingSession } = useCheckSession(sessionId);
  const { setChallengeId } = useContext(SocketContext);
  const [initializing, setInitializing] = useState(true);

  async function loadChallenge() {
    const { data } = await axios.get(
      `${RUNNER_ADDRESS}/runner/challenge/${language}/${challengeName}`
    );
    const { name, description, initialValue, title } = data;
    setChallenge({ description, name, initialValue, title });
    setCode(initialValue);
  }

  async function loadUserChallenge() {
    const challengeData: CreateChallenge = {
      sessionId: sessionId,
      language: language,
      name: challengeName,
    };

    try {
      const {
        data: { id, events },
      } = await axios.put<IChallenge>(
        `${API_ADDRESS}/api/challenges`,
        challengeData
      );

      setChallengeId(id);

      if (events.length > 0) {
        const lastEvent = events[events.length - 1];
        setCode(lastEvent.state.code);
        setSubmitted(lastEvent.state.submitted);
      } else {
        setSubmitted(false);
      }
    } catch (err) {
      setChallengeError(true);
    } finally {
      setLoadingChallenge(false);
    }
  }

  async function initialize() {
    await loadChallenge();
    await loadUserChallenge();
    setInitializing(false);
  }

  useEffect(() => {
    initialize();
  }, []);

  if (loadingSession || loadingChallenge || initializing) {
    return <Loader />;
  }

  if (sessionError) {
    return <CenteredContent>Session not found</CenteredContent>;
  }

  if (challengeError) {
    return <CenteredContent>Error loading challenge</CenteredContent>;
  }

  return (
    <Box
      sx={{
        height: '100%',
      }}
    >
      <Challenge />
    </Box>
  );
};
