import React, { useContext, useEffect } from 'react';
import { Route, Routes } from 'react-router-dom';
import { Header } from '../components/Header/Header';
import { SocketContext } from '../context/socketContext';
import { useSocket } from '../hooks/useSocket';
import { ChallengePage } from '../pages/ChallengePage';
import { ChallengeSelectionPage } from '../pages/ChallengeSelection';
import { LanguageSelectionPage } from '../pages/LanguageSelection';
import { WelcomePage } from '../pages/WelcomePage';
import { EditorContext } from './App';

export const CandidateApp = () => {
  const { connected, setConnected } = useContext(SocketContext);
  const { setWindowActive } = useContext(EditorContext);
  const { subscribe, connect } = useSocket();

  useEffect(() => {
    const unsubscribe = subscribe('disconnect', () => {
      setConnected(false);
    });

    if (!connected) {
      connect();
    }
    return unsubscribe;
  }, [connected]);

  useEffect(() => {
    const changeListener = () => {
      setWindowActive(document.visibilityState === 'visible');
    };
    document.addEventListener('visibilitychange', changeListener);

    return () =>
      document.removeEventListener('visibilitychange', changeListener);
  }, []);

  return (
    <>
      <Header />

      <Routes>
        <Route path=":sessionId" element={<WelcomePage />} />

        <Route
          path=":sessionId/select-language"
          element={<LanguageSelectionPage />}
        />

        <Route
          path=":sessionId/:language"
          element={<ChallengeSelectionPage />}
        />

        <Route
          path=":sessionId/:language/:challengeName"
          element={<ChallengePage />}
        />
      </Routes>
    </>
  );
};
