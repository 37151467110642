import React from 'react';
import {
  default as MUITextField,
  FilledTextFieldProps,
} from '@mui/material/TextField';
import classes from './TextField.module.css';

export const TextField: React.FC<FilledTextFieldProps> = (props) => {
  return (
    <MUITextField
      {...props}
      variant="filled"
      classes={{ root: classes.root }}
    />
  );
};
