import { Box } from '@mui/material';
import React, { ReactNode } from 'react';

interface Props {
  children: ReactNode;
}

function CenteredContent(props: Props) {
  return (
    <Box
      sx={{
        paddingTop: '16px',
        paddingBottom: '32px',
        display: 'flex',
        alignItems: 'center',
        flexDirection: 'column',
        overflowX: 'hidden',
      }}
    >
      {props.children}
    </Box>
  );
}

export default CenteredContent;
