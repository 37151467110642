import Typography from '@mui/material/Typography';
import React, { useContext } from 'react';
import { AuthContext, EditorContext } from '../../apps/App';
import { SocketContext } from '../../context/socketContext';
import { ConnectionStatus } from '../ConnectionStatus/ConnectionStatus';
import { Logo } from '../Logo/Logo';
import { WindowActiveStatus } from '../WindowActiveStatus/WindowActiveStatus';
import classes from './Header.module.css';
import { Link, useMatch } from 'react-router-dom';
import { Box } from '@mui/material';

export type HeaderProps = {
  isReviewer?: boolean;
  showTitle?: boolean;
  logoAsLink?: boolean;
};

export const Header: React.FC<HeaderProps> = ({
  isReviewer = false,
  showTitle = true,
  logoAsLink = false,
}) => {
  const { connected } = useContext(SocketContext);
  const challengeRouteMatch = useMatch(
    '/reviewer/sessions/:sessionId/challenges/:challengeId'
  );
  const { isAuthenticated } = useContext(AuthContext);

  const {
    editorState: { windowActive },
  } = useContext(EditorContext);

  function renderLogo() {
    if (logoAsLink && isAuthenticated) {
      return (
        <Link to="/reviewer/sessions">
          <Logo className={classes.logo} />
        </Link>
      );
    }
    return <Logo className={classes.logo} />;
  }

  return (
    <Box
      sx={{
        minHeight: '60px',
        bgcolor: 'black',
        display: 'flex',
        alignItems: 'center',
        paddingX: '16px',
        width: '100%',
        position: 'sticky',
      }}
    >
      {renderLogo()}

      {showTitle && (
        <Typography color="inherit" component="p">
          {isReviewer ? 'Recording review' : 'Assessment environment'}
        </Typography>
      )}

      <div className={classes.spacer}></div>

      {isReviewer && !!challengeRouteMatch && (
        <div className={classes.connectionStatus}>
          <WindowActiveStatus active={windowActive} />
        </div>
      )}

      {!isReviewer && (
        <div className={classes.connectionStatus}>
          <ConnectionStatus connected={connected} />
        </div>
      )}
    </Box>
  );
};
