import Icon from '@mui/material/Icon';
import React, { useContext } from 'react';
import { Link, useParams } from 'react-router-dom';
import { Button } from '../components/Button/Button';
import { Content } from '../components/Content/Content';
import classes from './WelcomePage.module.css';
import { useCheckSession } from '../hooks/useCheckSession';
import { SocketContext } from '../context/socketContext';

export const WelcomePage = () => {
  const { sessionId } = useParams() as { sessionId: string };
  const { sessionName } = useContext(SocketContext);
  const { sessionError, loading } = useCheckSession(sessionId);

  if (sessionError) {
    return (
      <Content>
        <p>Session not found</p>
      </Content>
    );
  }

  if (loading) {
    return (
      <Content>
        <p>Loading...</p>
      </Content>
    );
  }

  return (
    <Content>
      <h1>Welcome</h1>

      <p className={classes.text}>
        Welcome to the assessment environment, {sessionName}.
      </p>

      <p className={classes.text}>
        In order to get a good understanding of your skill level, please write
        all of your code in the online editor (Visual Studio Code).
        <br />
        <br />
        When you are done, you can submit your challenge.
      </p>

      <p className={classes.text}>
        <strong>Happy coding!</strong>
      </p>

      <Link to="select-language">
        <div className={classes.button}>
          <Button type="submit">
            Begin &nbsp;<Icon>play_circle_filled</Icon>
          </Button>
        </div>
      </Link>
    </Content>
  );
};
