import React from 'react';
import { Session } from '../../interfaces/Session';
import classes from './SessionDetail.module.css';
import { SessionDetailItem } from './SessionDetailItem';

export type RecordingListProps = {
  session: Session;
};

export const SessionDetail: React.FC<RecordingListProps> = ({ session }) => {
  return (
    <>
      <ul className={classes.list}>
        {session.challenges.map((challenge) => (
          <li key={challenge.id} className={classes.item}>
            <SessionDetailItem challenge={challenge} />
          </li>
        ))}
      </ul>
    </>
  );
};
