import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogContentText,
  DialogActions,
  Button,
} from '@mui/material';
import React from 'react';

interface ConfirmationDialogProps {
  title: string;
  text: string;
  isOpen: boolean;
  onClose: () => void;
  onConfirm: () => void;
  cancelText?: string;
  confirmText?: string;
  confirmationButtonColor?:
    | 'primary'
    | 'secondary'
    | 'success'
    | 'error'
    | 'info'
    | 'warning';
  loading?: boolean;
}

function ConfirmationDialog({
  title,
  text,
  isOpen,
  onClose,
  onConfirm,
  cancelText = 'Cancel',
  confirmText = 'Confirm',
  confirmationButtonColor = 'secondary',
  loading = false,
}: ConfirmationDialogProps) {
  return (
    <Dialog open={isOpen} onClose={onClose}>
      <DialogTitle color="black">{title}</DialogTitle>
      <DialogContent>
        <DialogContentText>{text}</DialogContentText>
      </DialogContent>
      <DialogActions>
        <Button onClick={onClose} variant="text" color="secondary">
          {cancelText}
        </Button>
        <Button
          color={confirmationButtonColor}
          variant="contained"
          onClick={onConfirm}
          disabled={loading}
        >
          {confirmText}
        </Button>
      </DialogActions>
    </Dialog>
  );
}

export default ConfirmationDialog;
