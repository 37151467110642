import React from 'react';
import { Link } from 'react-router-dom';
import classes from './Language.module.css';

export type LanguageProps = {
  language: string;
  link: string;
};

export const Language: React.FC<LanguageProps> = ({ language, link }) => {
  return (
    <Link to={link} className={classes.wrapper}>
      <img src={`/assets/${language}.svg`} />
    </Link>
  );
};
