import Editor, { OnChange, OnMount } from '@monaco-editor/react';
import { IPosition, Selection } from 'monaco-editor';
import React, { useEffect, useRef } from 'react';

export type MonacoEditorProps = {
  mode: string;
  value: string;
  cursor?: any;
  selection?: any;
  onChange?: (value: string) => void;
  onCursorChange?: (position: IPosition) => void;
  onSelectionChange?: (selection: Selection[]) => void;
  readonly: boolean;
};

export const MonacoEditor: React.FC<MonacoEditorProps> = ({
  onChange,
  value,
  cursor,
  selection,
  mode,
  onSelectionChange,
  onCursorChange,
  readonly,
}) => {
  const editorRef = useRef<any>(null);

  const handleChange: OnChange = (value) => {
    if (onChange && value) {
      onChange(value);
    }
  };

  const handleChangeSelection = (selections: Selection[] | null) => {
    if (onSelectionChange && selections) {
      onSelectionChange(selections);
    }
  };

  const handleChangeCursorPosition = (position: IPosition) => {
    if (onCursorChange) {
      onCursorChange(position);
    }
  };

  const onEditorMount: OnMount = (editor) => {
    editorRef.current = editor;

    editor.onDidChangeCursorPosition((event) =>
      handleChangeCursorPosition(event.position)
    );

    editor.onDidChangeCursorSelection((event) => {
      handleChangeSelection(editor.getSelections());
    });
  };

  useEffect(() => {
    if (editorRef.current) {
      editorRef.current.focus();

      if (cursor) {
        editorRef.current.setPosition(cursor);
      }

      if (selection) {
        editorRef.current.setSelections(selection);
      }

      const messageContribution = editorRef.current.getContribution(
        'editor.contrib.messageController'
      );
      editorRef.current.onDidAttemptReadOnlyEdit(() => {
        messageContribution.dispose();
      });
    }
  }, [cursor, selection, editorRef]);

  return (
    <Editor
      value={value}
      onMount={onEditorMount}
      height="100%"
      width="100%"
      options={{
        fontSize: 14,
        autoClosingBrackets: 'always',
        autoClosingQuotes: 'always',
        autoIndent: 'full',
        autoSurround: 'brackets',
        fixedOverflowWidgets: true,
        readOnly: readonly,
      }}
      theme={
        process.env.REACT_APP_THEME === 'nationalenederlanden'
          ? 'vs-light'
          : 'vs-dark'
      }
      language={mode}
      onChange={handleChange}
    />
  );
};
