import React from 'react';
import classes from './ChallengeList.module.css';
import { ChallengeListItem } from './ChallengeListItem';

export type ChallengeListProps = {
  language: string;
  challenges: {
    name: string;
    title: string;
    description: string;
  }[];
};

export const ChallengeList: React.FC<ChallengeListProps> = ({
  challenges,
  language,
}) => {
  return (
    <div className={classes.list}>
      {challenges.map((challenge) => (
        <div key={challenge.name}>
          <ChallengeListItem
            name={challenge.name}
            title={challenge.title}
            description={challenge.description}
            language={language}
          />
        </div>
      ))}
    </div>
  );
};
