import React from 'react';
import { Language } from './Language';
import classes from './LanguageList.module.css';
import { useParams } from 'react-router-dom';

export type LanguageListProps = {
  languages: string[];
};

export const LanguageList: React.FC<LanguageListProps> = ({ languages }) => {
  const { sessionId } = useParams<{
    sessionId: string;
  }>();

  return (
    <ul className={classes.list}>
      {languages.map((language) =>
        language.indexOf('.') === -1 ? (
          <li key={language} className={classes.item}>
            <Language
              language={language}
              link={`/candidate/${sessionId}/${language}`}
            />
          </li>
        ) : null
      )}
    </ul>
  );
};
