import axios from 'axios';
import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { Content } from '../components/Content/Content';
import { LanguageList } from '../components/LanguageList/LanguageList';
import Loader from '../components/Loader/Loader';
import { RUNNER_ADDRESS } from '../constants/path';
import { useCheckSession } from '../hooks/useCheckSession';

export const LanguageSelectionPage = () => {
  const { sessionId } = useParams() as { sessionId: string };
  const { sessionError, loading } = useCheckSession(sessionId);
  const [languages, setLanguages] = useState([]);

  useEffect(() => {
    async function loadLanguages() {
      const { data } = await axios.get(`${RUNNER_ADDRESS}/runner/languages`);
      setLanguages(data);
    }

    loadLanguages();
  }, []);

  if (sessionError) {
    return (
      <Content>
        <p>Session not found</p>
      </Content>
    );
  }

  if (loading) {
    return <Loader />;
  }

  return languages.length ? (
    <Content>
      <h1>Pick your poison</h1>
      <LanguageList languages={languages} />
    </Content>
  ) : (
    <Loader />
  );
};
