import Prism from 'prismjs';
import 'prismjs/components/prism-kotlin';
import 'prismjs/components/prism-javascript';
import 'prismjs/components/prism-typescript';
import 'prismjs/components/prism-java';
import 'prismjs/themes/prism-tomorrow.css';

export const formatDescription = async (
  description: string
): Promise<string> => {
  return new Promise((resolve, reject) => {
    const wrappingElement = document.createElement('div');
    wrappingElement.innerHTML = description;

    Prism.highlightAllUnder(wrappingElement, false);

    resolve(wrappingElement.innerHTML);
  });
};
