import React, { useState, ReactNode } from 'react';
import io from 'socket.io-client';
import { SOCKET_ADDRESS } from '../constants/path';

type SocketContext = {
  socket: SocketIOClient.Socket;
  connected: boolean;
  sessionId: string | null;
  sessionName: string | null;
  challengeId: string | null;
  setConnected: (connected: boolean) => void;
  setSessionId: (sessionId: string) => void;
  setSessionName: (sessionName: string) => void;
  setChallengeId: (challengeId: string) => void;
};

const socket = io(SOCKET_ADDRESS, {
  autoConnect: false,
  path: '/socket',
});

export const SocketContext = React.createContext<SocketContext>(
  {} as SocketContext
);

export const SocketProvider = ({ children }: { children: ReactNode }) => {
  const [connected, setConnected] = useState(false);
  const [sessionId, setSessionId] = useState<string | null>(null);
  const [sessionName, setSessionName] = useState<string | null>(null);
  const [challengeId, setChallengeId] = useState<string | null>(null);

  const value = {
    socket,
    connected,
    sessionId,
    sessionName,
    challengeId,
    setConnected,
    setSessionId,
    setSessionName,
    setChallengeId,
  };

  return (
    <SocketContext.Provider value={value}>{children}</SocketContext.Provider>
  );
};
