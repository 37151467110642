import React, { useContext } from 'react';
import { useQuery } from 'react-query';
import { useParams } from 'react-router-dom';
import { EditorContext } from '../apps/App';
import CenteredContent from '../components/CenteredContent';
import { Challenge } from '../components/Challenge/Challenge';
import Loader from '../components/Loader/Loader';
import { RecordingTimeline } from '../components/RecordingTimeline/RecordingTimeline';
import { Challenge as IChallenge } from '../interfaces/Challenge';
import api from '../utils/api';

export const RecordingViewPage = () => {
  const { challengeId } = useParams<{ challengeId: string }>();

  const { setEditorState } = useContext(EditorContext);
  const { isLoading, data } = useQuery<IChallenge>(
    ['recordings', challengeId],
    async () => {
      const response = await api.get(`/api/challenges/${challengeId}`);
      return response.data;
    }
  );

  const handleFrameChange = (frame: number) => {
    const event = data ? data.events[frame] : null;

    if (event) {
      return setEditorState(event.state);
    }
  };

  if (isLoading) {
    return <Loader />;
  }

  if (!data) {
    return <CenteredContent>Session not found</CenteredContent>;
  }

  return (
    <>
      <RecordingTimeline
        events={data.events}
        onFrameChange={handleFrameChange}
      />

      <Challenge isReviewer />
    </>
  );
};
