import React from 'react';
import { Link } from 'react-router-dom';
import classes from './ChallengeListItem.module.css';

export type ChallengeListItemProps = {
  name: string;
  title: string;
  description: string;
  language: string;
};

export const ChallengeListItem: React.FC<ChallengeListItemProps> = ({
  description,
  name,
  title,
}) => {
  return (
    <Link className={classes.wrapper} to={name}>
      <h2>{title}</h2>

      <p
        className={classes.description}
        dangerouslySetInnerHTML={{ __html: description }}
      />
    </Link>
  );
};
