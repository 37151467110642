import { IPosition, Selection } from 'monaco-editor';
import React, { ReactNode, useState } from 'react';
import { EditorContext } from '../apps/App';

interface Props {
  children: ReactNode;
}

interface EditorChallenge {
  description: string;
  initialValue: string;
  name: string;
  title: string;
}

export interface EditorState {
  code: string;
  output: string;
  cursor: IPosition | null;
  selection: Selection[] | null;
  windowActive: boolean;
  challenge: EditorChallenge | null;
  submitted: boolean;
}

export interface EditorContextData {
  editorState: EditorState;
  setCode: (code: string) => void;
  setOutput: (output: string) => void;
  setCursor: (cursor: IPosition) => void;
  setSelection: (selection: Selection[]) => void;
  setWindowActive: (windowActive: boolean) => void;
  setChallenge: (challenge: EditorChallenge) => void;
  setEditorState: (state: EditorState) => void;
  setSubmitted: (submitted: boolean) => void;
}

function EditorProvider(props: Props) {
  const [code, setCode] = useState('');
  const [submitted, setSubmitted] = useState(false);
  const [output, setOutput] = useState('');
  const [cursor, setCursor] = useState<IPosition | null>(null);
  const [windowActive, setWindowActive] = useState(true);
  const [challenge, setChallenge] = useState<EditorChallenge | null>(null);
  const [selection, setSelection] = useState<Selection[] | null>(null);

  const editorState: EditorState = {
    code,
    submitted,
    output,
    cursor,
    selection,
    windowActive,
    challenge,
  };

  function setEditorState(state: EditorState) {
    setCode(state.code);
    setSubmitted(state.submitted);
    setOutput(state.output);
    setWindowActive(state.windowActive);
    setCursor(state.cursor);
    setSelection(state.selection);
    setChallenge(state.challenge);
  }

  const value = {
    editorState,
    setEditorState,
    setCode,
    setOutput,
    setWindowActive,
    setCursor,
    setChallenge,
    setSelection,
    setSubmitted,
  };

  return (
    <EditorContext.Provider value={value}>
      {props.children}
    </EditorContext.Provider>
  );
}

export default EditorProvider;
