import axios from 'axios';
import { useContext, useEffect, useState } from 'react';
import { API_ADDRESS } from '../constants/path';
import { SocketContext } from '../context/socketContext';
import { Session } from '../interfaces/Session';
import { useSocket } from './useSocket';

export function useCheckSession(sessionId: string) {
  const [loading, setLoading] = useState(false);
  const [sessionError, setSessionError] = useState(false);
  const { newSession } = useSocket();

  const { sessionName, setSessionName, setSessionId } =
    useContext(SocketContext);

  const fetchSession = async () => {
    try {
      setLoading(true);
      const { data } = await axios.get<Session>(
        `${API_ADDRESS}/api/sessions/${sessionId}`
      );
      newSession(data.id);
      setSessionId(data.id);
      setSessionName(data.firstName);
    } catch (err) {
      setSessionError(true);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    if (!sessionName) {
      fetchSession();
    }
  }, []);

  if (sessionName) {
    return { loading: false, sessionError: false };
  }

  return {
    loading,
    sessionError,
  };
}
