import React from 'react';
import { QueryClient, QueryClientProvider } from 'react-query';
import { Route, Routes } from 'react-router-dom';
import EditSession from '../components/EditSession';
import { Header } from '../components/Header/Header';
import ProtectedRoute from '../components/ProtectedRoute';
import { SessionDetailPage } from '../components/SessionDetail/SessionDetailPage';
import NewSession from '../pages/NewSession';
import { RecordingViewPage } from '../pages/RecordingViewPage';
import { ReviewerAuthPage } from '../pages/ReviewerAuthPage';
import { SessionListPage } from '../pages/SessionListPage';

export type ReviewerAppProps = {};
const queryClient = new QueryClient();

export const ReviewerApp: React.FC<ReviewerAppProps> = () => {
  return (
    <QueryClientProvider client={queryClient}>
      <Header isReviewer logoAsLink />

      <Routes>
        <Route path="" element={<ReviewerAuthPage />} />

        <Route
          path="sessions"
          element={
            <ProtectedRoute>
              <SessionListPage />
            </ProtectedRoute>
          }
        />

        <Route
          path="new-session"
          element={
            <ProtectedRoute>
              <NewSession />
            </ProtectedRoute>
          }
        />

        <Route
          path="sessions/:sessionId"
          element={
            <ProtectedRoute>
              <SessionDetailPage />
            </ProtectedRoute>
          }
        />

        <Route
          path="sessions/:sessionId/edit"
          element={
            <ProtectedRoute>
              <EditSession />
            </ProtectedRoute>
          }
        />

        <Route
          path="sessions/:sessionId/challenges/:challengeId"
          element={
            <ProtectedRoute>
              <RecordingViewPage />
            </ProtectedRoute>
          }
        />
      </Routes>
    </QueryClientProvider>
  );
};
