import React from 'react';
import Play from '@mui/icons-material/PlayCircleFilled';
import Pause from '@mui/icons-material/PauseCircleFilled';
import ToggleButtonGroup from '@mui/lab/ToggleButtonGroup';
import ToggleButton from '@mui/lab/ToggleButton';
import classes from './Controls.module.css';
import { Box } from '@mui/material';

export type ControlsProps = {
  isPlaying: boolean;
  speed: number;
  onPause: () => void;
  onPlay: () => void;
  onSpeedChange: (speed: number) => void;
};

export const Controls: React.FC<ControlsProps> = ({
  isPlaying,
  speed,
  onPause,
  onPlay,
  onSpeedChange,
}) => {
  return (
    <Box
      sx={{
        display: 'flex',
        alignItems: 'center',
        marginRight: '12px',
      }}
    >
      <div>
        <Box
          onClick={() => (isPlaying ? onPause() : onPlay())}
          sx={{ cursor: 'pointer', mr: 1 }}
        >
          {isPlaying ? (
            <Pause sx={{ fill: 'white' }} />
          ) : (
            <Play sx={{ fill: 'white' }} />
          )}
        </Box>
      </div>

      <div className={classes.slider}>
        <ToggleButtonGroup
          value={speed}
          exclusive
          size="small"
          onChange={(_, value) => onSpeedChange(value)}
        >
          <ToggleButton value={1}>
            <span>1x</span>
          </ToggleButton>
          <ToggleButton value={2}>
            <span>2x</span>
          </ToggleButton>
          <ToggleButton value={3}>
            <span>3x</span>
          </ToggleButton>
        </ToggleButtonGroup>
      </div>
    </Box>
  );
};
