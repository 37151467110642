import { DesktopAccessDisabledOutlined } from '@mui/icons-material';
import DesktopWindowsIcon from '@mui/icons-material/DesktopWindows';
import { Typography } from '@mui/material';
import React from 'react';
import classes from './WindowActiveStatus.module.css';

export type WindowActiveStatusProps = {
  active: boolean;
};

export const WindowActiveStatus: React.FC<WindowActiveStatusProps> = ({
  active,
}) => {
  return active ? (
    <div className={classes.wrapper}>
      <DesktopWindowsIcon />
      <Typography variant="caption" className={classes.text}>
        Window has focus
      </Typography>
    </div>
  ) : (
    <div className={classes.wrapper}>
      <DesktopAccessDisabledOutlined color="error" />
      <Typography variant="caption" color="error" className={classes.text}>
        Window focus lost
      </Typography>
    </div>
  );
};
