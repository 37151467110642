import axios from 'axios';
import React, { useEffect, useState, useContext } from 'react';
import { useParams } from 'react-router-dom';
import { ChallengeList } from '../components/ChallengeList/ChallengeList';
import { Content } from '../components/Content/Content';
import { RUNNER_ADDRESS } from '../constants/path';
import { useCheckSession } from '../hooks/useCheckSession';
import { EditorContext } from '../apps/App';
import { Box } from '@mui/material';

export type ChallengeSelectionPageProps = {};

export const ChallengeSelectionPage: React.FC<
  ChallengeSelectionPageProps
> = () => {
  const { sessionId, language } = useParams() as {
    sessionId: string;
    language: string;
  };
  const { sessionError, loading } = useCheckSession(sessionId);
  const [challenges, setChallenges] = useState([]);
  const { setOutput } = useContext(EditorContext);

  useEffect(() => {
    async function loadChallenge() {
      const { data } = await axios.get(
        `${RUNNER_ADDRESS}/runner/challenge/${language}`
      );

      setChallenges(data);
    }

    setOutput('');
    loadChallenge();
  }, []);

  if (sessionError) {
    return (
      <Content>
        <p>Session not found</p>
      </Content>
    );
  }

  if (loading) {
    return (
      <Content>
        <p>Loading...</p>
      </Content>
    );
  }

  return (
    <Box sx={{ padding: '16px', overflow: 'scroll' }}>
      {challenges.length ? (
        <Box
          sx={{
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
          }}
        >
          <h1>Pick a challenge</h1>

          <ChallengeList language={language} challenges={challenges} />
        </Box>
      ) : (
        <p>Loading..</p>
      )}
    </Box>
  );
};
