import { Box, Button, Chip } from '@mui/material';
import Icon from '@mui/material/Icon';
import DoneIcon from '@mui/icons-material/Done';
import axios from 'axios';
import React, { useContext, useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { RUNNER_ADDRESS } from '../../constants/path';
import { useSocket } from '../../hooks/useSocket';
import api from '../../utils/api';
import { MonacoEditor, MonacoEditorProps } from '../MonacoEditor/MonacoEditor';
import classes from './Challenge.module.css';
import { formatDescription } from './library/formatDescription';
import { formatOutput } from './library/formatOutput';
import SubmitChallengeModal from './SubmitChallengeModal';
import { EditorContext } from '../../apps/App';
import { SocketContext } from '../../context/socketContext';

interface Props {
  isReviewer?: boolean;
}

export const Challenge = ({ isReviewer = false }: Props) => {
  const { sessionId, language, challengeName } = useParams<{
    sessionId: string;
    language: string;
    challengeName: string;
  }>();
  const {
    editorState,
    setCode,
    setCursor,
    setOutput,
    setSelection,
    setSubmitted,
  } = useContext(EditorContext);
  const {
    code,
    output,
    selection,
    cursor,
    challenge,
    submitted,
    windowActive,
  } = editorState;
  const { connected, challengeId } = useContext(SocketContext);
  const [formattedDescription, setFormattedDescription] = useState('');
  const { sendSessionState } = useSocket();
  const [showSubmitModal, setShowSubmitModal] = useState(false);
  const [submitting, setSubmitting] = useState(false);

  // Do not add code and cursor to the dependency array.
  // This will cause extra events with no new data to be stored
  // and makes the cursor lag when replaying the challenge.
  useEffect(() => {
    if (connected && !submitted) {
      sendSessionState(editorState);
    }
  }, [connected, submitted, windowActive, selection, output]);

  const execute = async () => {
    setOutput(`<span class="${classes.running}">Sending request...</span>`);
    const { data } = await axios.post(`${RUNNER_ADDRESS}/runner/execute`, {
      code,
      type: language,
      userId: sessionId,
      challengeName,
    });
    const { output, error } = data;
    const formattedConsole = formatOutput({ output, error }, classes);
    setOutput(formattedConsole);
  };

  const handleRun = async () => {
    await execute();
  };

  useEffect(() => {
    async function format() {
      if (challenge) {
        const formatted = await formatDescription(challenge.description);
        setFormattedDescription(formatted);
      }
    }

    format();
  }, [challenge]);

  const onChangeProps: Partial<MonacoEditorProps> = isReviewer
    ? { cursor, selection }
    : {
        onChange: setCode,
        onCursorChange: setCursor,
        onSelectionChange: setSelection,
      };

  const openSubmitModal = () => {
    setShowSubmitModal(true);
  };

  const closeSubmitModal = () => {
    setShowSubmitModal(false);
  };

  const submitChallenge = async (remark: string) => {
    setSubmitting(true);
    try {
      await api.patch(`/api/challenges/${challengeId}`, { remark });
      setSubmitted(true);
      // record state here, because the effect earlier only runs if submitted is false
      sendSessionState({ ...editorState, submitted: true });
      setShowSubmitModal(false);
    } catch (err) {
      console.error(err);
    } finally {
      setSubmitting(false);
    }
  };

  function ButtonBar() {
    if (isReviewer) {
      return null;
    }

    return (
      <Box
        sx={{
          display: 'flex',
          justifyContent: 'space-between',
          alignItems: 'flex-end',
          height: '100%',
          padding: '12px',
        }}
      >
        {submitted ? (
          <Chip
            label="Challenge completed"
            icon={<DoneIcon />}
            color="primary"
            sx={{ fontWeight: 'bold' }}
          />
        ) : (
          <>
            <Button onClick={openSubmitModal} size="medium" variant="contained">
              Submit &nbsp;<Icon>done</Icon>
            </Button>

            <Button
              onClick={handleRun}
              size="medium"
              variant="contained"
              color="info"
            >
              Run tests &nbsp;<Icon>play_arrow</Icon>
            </Button>
          </>
        )}
      </Box>
    );
  }

  return (
    <div className={classes.container}>
      <SubmitChallengeModal
        isSubmitting={submitting}
        isOpen={showSubmitModal}
        onClose={closeSubmitModal}
        onSubmit={submitChallenge}
      />
      <div className={classes.leftSide}>
        <div className={classes.editor}>
          <MonacoEditor
            {...onChangeProps}
            value={code}
            mode={language || 'javascript'}
            readonly={isReviewer || !!submitted}
          />
        </div>
      </div>

      <div className={classes.rightSide}>
        <Box
          className={classes.description}
          sx={{ height: output ? '42%' : '92%' }}
        >
          <h3>Description</h3>

          <p
            dangerouslySetInnerHTML={{
              __html: formattedDescription,
            }}
          />
        </Box>

        {output && (
          <Box className={classes.output} sx={{ height: '43%' }}>
            <p className="line" dangerouslySetInnerHTML={{ __html: output }} />
          </Box>
        )}

        <Box sx={{ height: '8%' }}>
          <ButtonBar />
        </Box>
      </div>
    </div>
  );
};
