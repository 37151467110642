import React from 'react';

export const CodeCapiLogo = ({ ...rest }) => (
  <svg
    {...rest}
    id="Layer_1"
    data-name="Layer 1"
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 465.62 90.84"
  >
    <path d="M-.18,3.63H21.09v8.91H10.94V78.06H21.09V87H-.18Z" />
    <path d="M38.45,67.2a23,23,0,0,1-9.17-8.68A24.32,24.32,0,0,1,26,45.92a24.09,24.09,0,0,1,3.29-12.55,23.1,23.1,0,0,1,9.13-8.64,27.88,27.88,0,0,1,13.31-3.11,25.62,25.62,0,0,1,12.33,2.85,18.5,18.5,0,0,1,8,8.19l-8.55,5a13.65,13.65,0,0,0-5.12-4.94,14,14,0,0,0-6.72-1.64A14.08,14.08,0,0,0,41.3,35.11q-4.09,4.05-4.09,10.81t4,10.82a14,14,0,0,0,10.37,4,14,14,0,0,0,6.72-1.65,13.5,13.5,0,0,0,5.12-4.94l8.55,5A19.28,19.28,0,0,1,64,67.42a25.11,25.11,0,0,1-12.24,2.9A27.86,27.86,0,0,1,38.45,67.2Z" />
    <path d="M89.29,67.2a22.75,22.75,0,0,1-9-8.68A24.49,24.49,0,0,1,77,45.92a24.25,24.25,0,0,1,3.25-12.55,22.86,22.86,0,0,1,9-8.64,28.89,28.89,0,0,1,26.08,0,22.86,22.86,0,0,1,9,8.64,24.25,24.25,0,0,1,3.25,12.55,24.49,24.49,0,0,1-3.25,12.6,22.75,22.75,0,0,1-9,8.68,28.81,28.81,0,0,1-26.08,0Zm23.15-10.5q4-4.1,4-10.78a14.74,14.74,0,0,0-4-10.77,13.62,13.62,0,0,0-10.15-4.09,13.45,13.45,0,0,0-10.11,4.09q-4,4.1-4,10.77t4,10.78a13.45,13.45,0,0,0,10.11,4.09A13.62,13.62,0,0,0,112.44,56.7Z" />
    <path d="M184.9,3.63V69.69H174.22V63.55a17.09,17.09,0,0,1-6.81,5.07,23,23,0,0,1-8.95,1.7,25,25,0,0,1-12.33-3,21.77,21.77,0,0,1-8.59-8.59,25.68,25.68,0,0,1-3.11-12.78,25.44,25.44,0,0,1,3.11-12.73,21.77,21.77,0,0,1,8.59-8.54,25,25,0,0,1,12.33-3,22.57,22.57,0,0,1,8.64,1.6A17.46,17.46,0,0,1,173.78,28V3.63ZM167,59a13.18,13.18,0,0,0,5.07-5.21A15.92,15.92,0,0,0,174,45.92a15.91,15.91,0,0,0-1.87-7.83A13.11,13.11,0,0,0,167,32.88a15.19,15.19,0,0,0-14.42,0,13.2,13.2,0,0,0-5.08,5.21,16,16,0,0,0-1.87,7.83,16,16,0,0,0,1.87,7.84A13.27,13.27,0,0,0,152.59,59,15.19,15.19,0,0,0,167,59Z" />
    <path d="M244,49.48h-37.3a12.94,12.94,0,0,0,5.11,8.33,16.78,16.78,0,0,0,10.29,3.07A17.35,17.35,0,0,0,235,55.72l6,6.85a20.21,20.21,0,0,1-8.1,5.79,32.08,32.08,0,0,1-24.84-1.16,22.38,22.38,0,0,1-9.21-8.68,24.49,24.49,0,0,1-3.25-12.6,24.85,24.85,0,0,1,3.16-12.51,22.45,22.45,0,0,1,8.77-8.68,25.65,25.65,0,0,1,12.64-3.11,24.92,24.92,0,0,1,12.42,3.07,21.8,21.8,0,0,1,8.55,8.64,26.24,26.24,0,0,1,3.07,12.86C244.2,47,244.14,48.06,244,49.48Zm-32.9-15.8a13.29,13.29,0,0,0-4.49,8.42h27a13.08,13.08,0,0,0-4.36-8.37,13.25,13.25,0,0,0-9.08-3.21A13.46,13.46,0,0,0,211.12,33.68Z" />
    <path d="M249.09,87v-8.9h10.06V12.54H249.09V3.63h21.19V87Z" />
    <path d="M294.5,67a21.62,21.62,0,0,1-8.37-8.41,24.34,24.34,0,0,1-3-12.15,24.53,24.53,0,0,1,3-12.24,21.39,21.39,0,0,1,8.37-8.37,24.36,24.36,0,0,1,12.1-3,23.38,23.38,0,0,1,10.2,2.19,18.39,18.39,0,0,1,7.43,6.36l-3.29,2.41a15.73,15.73,0,0,0-6.19-5.21,18.88,18.88,0,0,0-8.15-1.74,19.38,19.38,0,0,0-9.74,2.45,17.36,17.36,0,0,0-6.77,6.9,20.93,20.93,0,0,0-2.45,10.24,20.93,20.93,0,0,0,2.45,10.24,17.39,17.39,0,0,0,6.77,6.89A19.28,19.28,0,0,0,306.6,66a19,19,0,0,0,8.15-1.73,15.9,15.9,0,0,0,6.19-5.21l3.29,2.4a18.49,18.49,0,0,1-7.43,6.37,23.52,23.52,0,0,1-10.2,2.18A24.07,24.07,0,0,1,294.5,67Z" />
    <path d="M366.52,27.27q4.64,4.41,4.63,13V69.69h-4.27V61.41a15.48,15.48,0,0,1-6.5,6.37,21.62,21.62,0,0,1-10.24,2.27q-7.75,0-12.24-3.65a11.77,11.77,0,0,1-4.5-9.62,11.88,11.88,0,0,1,4.14-9.39q4.14-3.6,13.22-3.6H366.7V40.05q0-6.49-3.52-9.89T352.9,26.78a25,25,0,0,0-8.86,1.6,22.3,22.3,0,0,0-7.17,4.28l-2.22-3.21A24.8,24.8,0,0,1,343,24.6a30.3,30.3,0,0,1,10.24-1.74Q361.89,22.86,366.52,27.27ZM360.6,63.73A16.25,16.25,0,0,0,366.7,56V47.26H350.85q-6.86,0-9.93,2.49a8.37,8.37,0,0,0-3.07,6.86,8.57,8.57,0,0,0,3.39,7.16q3.38,2.62,9.52,2.63A17.48,17.48,0,0,0,360.6,63.73Z" />
    <path d="M424.57,25.89a21.7,21.7,0,0,1,8.23,8.37,24.58,24.58,0,0,1,3,12.2,24.82,24.82,0,0,1-3,12.24,21.3,21.3,0,0,1-8.23,8.37,23.39,23.39,0,0,1-11.76,3,21.72,21.72,0,0,1-11.12-2.89,20,20,0,0,1-7.75-8.06V87h-4.45V23.22h4.27v11a20.31,20.31,0,0,1,7.79-8.32,21.65,21.65,0,0,1,11.26-3A23.12,23.12,0,0,1,424.57,25.89Zm-2.41,37.66a17.73,17.73,0,0,0,6.72-6.94,20.63,20.63,0,0,0,2.45-10.15,20.63,20.63,0,0,0-2.45-10.15,17.83,17.83,0,0,0-6.72-7,18.73,18.73,0,0,0-9.61-2.49A18.51,18.51,0,0,0,403,29.36a17.94,17.94,0,0,0-6.68,7,20.74,20.74,0,0,0-2.45,10.15,20.74,20.74,0,0,0,2.45,10.15A17.84,17.84,0,0,0,403,63.55,18.51,18.51,0,0,0,412.55,66,18.73,18.73,0,0,0,422.16,63.55Z" />
    <path d="M449.23,10.89a3.35,3.35,0,0,1-1.07-2.54,3.48,3.48,0,0,1,1.07-2.58,3.52,3.52,0,0,1,2.58-1.07,3.6,3.6,0,0,1,2.58,1,3.38,3.38,0,0,1,1.07,2.53,3.56,3.56,0,0,1-1.07,2.59,3.51,3.51,0,0,1-2.58,1.06A3.59,3.59,0,0,1,449.23,10.89Zm.35,12.33H454V69.69h-4.45Z" />
  </svg>
);
