import { Link as MuiLink } from '@mui/material';
import { Box } from '@mui/system';
import React from 'react';
import { Link as RouterLink } from 'react-router-dom';
import CenteredContent from '../components/CenteredContent';
import { Header } from '../components/Header/Header';

export const HomePage = () => {
  return (
    <>
      <Header isReviewer showTitle={false} />

      <CenteredContent>
        <Box sx={{ textAlign: 'center', maxWidth: '500px' }}>
          <h1>Assessment environment</h1>

          <Box sx={{ mb: 4 }}>
            <h3>Candidate</h3>
            <p>
              This is the [code]capi assessment environment. You should have
              received an email with a link to your assessment.
            </p>
          </Box>

          <MuiLink
            to="reviewer"
            component={RouterLink}
            underline="hover"
            color="white"
          >
            <h3>Reviewer</h3>
          </MuiLink>
        </Box>
      </CenteredContent>
    </>
  );
};
