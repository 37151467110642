import { Search } from '@mui/icons-material';
import { Icon, InputAdornment, styled } from '@mui/material';
import TextField from '@mui/material/TextField';
import React, { useMemo, useState } from 'react';
import { useQuery } from 'react-query';
import { Link } from 'react-router-dom';
import { Button } from '../components/Button/Button';
import CenteredContent from '../components/CenteredContent';
import Loader from '../components/Loader/Loader';
import { SessionList } from '../components/SessionList/SessionList';
import { Session } from '../interfaces/Session';
import api from '../utils/api';

const CssTextField = styled(TextField)({
  input: {
    color: 'white',
  },
  '& label.Mui-focused': {
    color: 'white',
  },
  '& .MuiInput-underline:after': {
    borderBottomColor: 'white',
  },
  '& .MuiOutlinedInput-root': {
    '& fieldset': {
      borderColor: 'white',
    },
    '&:hover fieldset': {
      borderColor: 'white',
    },
    '&.Mui-focused fieldset': {
      borderColor: 'white',
      color: 'white',
    },
  },
});

export const SessionListPage = () => {
  const [search, setSearch] = useState('');
  const { isLoading, data = [] } = useQuery<Session[]>('sessions', async () => {
    const response = await api.get<Session[]>('/api/sessions');
    return response.data;
  });

  const filteredSessions = useMemo(() => {
    if (!search) {
      return data;
    }
    const match = search.toLowerCase();
    return data.filter((session) => {
      return (
        session.name.toLowerCase().includes(match) ||
        session.email.toLowerCase().includes(match)
      );
    });
  }, [data, search]);

  if (isLoading) {
    return <Loader />;
  }

  return (
    <CenteredContent>
      <h1>Sessions</h1>

      <Link to="../new-session">
        <Button sx={{ mb: 3 }}>
          New session <Icon sx={{ ml: 1 }}>add_circle</Icon>
        </Button>
      </Link>

      <CssTextField
        variant="outlined"
        placeholder="Search session"
        onChange={(e) => setSearch(e.target.value)}
        InputProps={{
          startAdornment: (
            <InputAdornment position="start">
              <Search sx={{ color: 'white' }} />
            </InputAdornment>
          ),
        }}
      />

      {data.length > 0 ? (
        <SessionList sessions={filteredSessions} />
      ) : (
        <p>No sessions found</p>
      )}
    </CenteredContent>
  );
};
