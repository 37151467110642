import { Icon } from '@mui/material';
import { Box } from '@mui/system';
import dayjs from 'dayjs';
import React from 'react';
import { Link } from 'react-router-dom';
import { dateFormat } from '../../constants/dates';
import { Session } from '../../interfaces/Session';
import classes from './SessionListItem.module.css';

export type SessionListItemProps = {
  session: Session;
};

export const SessionListItem: React.FC<SessionListItemProps> = ({
  session,
}) => {
  const started = session.challenges.length > 0;

  return (
    <Link className={classes.wrapper} to={session.id}>
      <h2>{session.name}</h2>

      <p className={classes.info}>
        <b>Created:</b> {dayjs(session.createdAt).format(dateFormat)}
      </p>

      <p className={classes.info}>
        <b>Email:</b> {session.email}
      </p>

      <p className={`${classes.info} ${classes.centered}`}>
        <b>Invited: </b>
        {session.invitedAt ? <Icon>check</Icon> : <Icon>close</Icon>}
      </p>

      <p className={`${classes.info} ${classes.centered}`}>
        <b>Started:</b>
        {started ? <Icon>check</Icon> : <Icon>close</Icon>}
      </p>

      <p className={classes.info}>
        <b>Challenges:</b> {session.challenges.length}
      </p>

      <p className={classes.info}></p>
    </Link>
  );
};
