import { grey } from '@mui/material/colors';
import { createTheme, ThemeProvider } from '@mui/material/styles';
import { SnackbarProvider } from 'notistack';
import React, { createContext } from 'react';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import AuthProvider, { AuthContextData } from '../context/authContext';
import EditorProvider, { EditorContextData } from '../context/editorContext';
import { SocketProvider } from '../context/socketContext';
import { HomePage } from '../pages/HomePage';
import './App.css';
import { CandidateApp } from './CandidateApp';
import { ReviewerApp } from './ReviewerApp';

export const EditorContext = createContext<EditorContextData>(
  {} as EditorContextData
);

export const AuthContext = createContext<AuthContextData>(
  {} as AuthContextData
);

const theme = createTheme({
  palette: {
    primary: {
      main: '#13ca91',
      dark: '#13af7e',
      contrastText: '#fff',
    },
    secondary: {
      main: grey[600],
      dark: grey[800],
    },
  },
});

function App() {
  return (
    <SnackbarProvider maxSnack={3} autoHideDuration={3000}>
      <ThemeProvider theme={theme}>
        <div className="App">
          <Router>
            <AuthProvider>
              <SocketProvider>
                <EditorProvider>
                  <Routes>
                    <Route path="/" element={<HomePage />} />
                    <Route path="/reviewer/*" element={<ReviewerApp />} />
                    <Route path="/candidate/*" element={<CandidateApp />} />
                  </Routes>
                </EditorProvider>
              </SocketProvider>
            </AuthProvider>
          </Router>
        </div>
      </ThemeProvider>
    </SnackbarProvider>
  );
}

export default App;
