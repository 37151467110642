import { Email, Person } from '@mui/icons-material';
import {
  Button,
  Icon,
  List,
  ListItem,
  ListItemButton,
  ListItemIcon,
  ListItemText,
  Stack,
} from '@mui/material';
import { useSnackbar } from 'notistack';
import React, { useState } from 'react';
import { useQuery } from 'react-query';
import { Link, useNavigate, useParams } from 'react-router-dom';
import { Session } from '../../interfaces/Session';
import api from '../../utils/api';
import CenteredContent from '../CenteredContent';
import ConfirmationDialog from '../ConfirmationDialog';
import Loader from '../Loader/Loader';
import { SessionDetail } from './SessionDetail';

interface ListProps {
  name: string;
  email: string;
}

function SessionDetailList({ name, email }: ListProps) {
  return (
    <List>
      <ListItem disablePadding>
        <ListItemButton>
          <ListItemIcon>
            <Person sx={{ color: 'white' }} />
          </ListItemIcon>
          <ListItemText primary={name} />
        </ListItemButton>
      </ListItem>

      <ListItem disablePadding>
        <ListItemButton>
          <ListItemIcon>
            <Email sx={{ color: 'white' }} />
          </ListItemIcon>
          <ListItemText primary={email} />
        </ListItemButton>
      </ListItem>
    </List>
  );
}

export const SessionDetailPage = () => {
  const [copyButtonText, setCopyButtonText] = useState('Copy candidate url');
  const { sessionId } = useParams<{ sessionId: string }>();
  const [showDeleteModal, setShowDeleteModal] = useState(false);
  const [showInviteModal, setShowInviteModal] = useState(false);
  const [loading, setLoading] = useState(false);
  const navigate = useNavigate();
  const { enqueueSnackbar } = useSnackbar();

  function copyCandidateUrl() {
    setCopyButtonText('Copied!');
    const url = `${window.location.host}/candidate/${sessionId}`;
    navigator.clipboard.writeText(url);
    setTimeout(() => {
      setCopyButtonText('Copy candidate url');
    }, 1000);
  }

  const { isLoading, data } = useQuery<Session>(
    ['sessions', sessionId],
    async () => {
      const { data } = await api.get<Session>(`/api/sessions/${sessionId}`);
      return {
        ...data,
        challenges: data.challenges.filter(
          (challenge) => challenge.events.length > 0
        ),
      };
    }
  );

  if (isLoading || !data) {
    return <Loader />;
  }

  async function sendInvitation() {
    setLoading(true);
    try {
      await api.post(`/api/sessions/${sessionId}/invite`);
      setShowInviteModal(false);
      enqueueSnackbar('Email sent', { variant: 'success' });
    } catch (err) {
      console.error(err);
    } finally {
      setLoading(false);
    }
  }

  async function deleteSession() {
    setLoading(true);
    try {
      await api.delete(`/api/sessions/${sessionId}`);
      enqueueSnackbar('Session deleted', { variant: 'info' });
      navigate('../sessions');
    } catch (err) {
      console.error(err);
    } finally {
      setLoading(false);
    }
  }

  return (
    <CenteredContent>
      <ConfirmationDialog
        title="Send invitation"
        text={`You are about to send an invitation to ${data.email}.`}
        isOpen={showInviteModal}
        onClose={() => setShowInviteModal(false)}
        onConfirm={sendInvitation}
        confirmationButtonColor="info"
        loading={loading}
      />

      <ConfirmationDialog
        title="Delete session"
        text={`Are you sure you want to delete the session of ${data.name}? This will remove all associated data.`}
        isOpen={showDeleteModal}
        onClose={() => setShowDeleteModal(false)}
        onConfirm={deleteSession}
        confirmText="Delete"
        confirmationButtonColor="error"
        loading={loading}
      />

      <h2>Session details</h2>

      <SessionDetailList name={data.name} email={data.email} />

      {data.challenges.length > 0 ? (
        <SessionDetail session={data} />
      ) : (
        <p>No recorded challenges found.</p>
      )}

      <Stack spacing={3} mt={3}>
        <Button color="info" variant="contained" onClick={copyCandidateUrl}>
          {copyButtonText}
          <Icon sx={{ ml: 1 }}>content_copy</Icon>
        </Button>

        <Link to="edit">
          <Button variant="contained" color="info" sx={{ width: '100%' }}>
            Update session <Icon sx={{ ml: 1 }}>edit</Icon>
          </Button>
        </Link>

        <Button
          color="info"
          variant="contained"
          onClick={() => setShowInviteModal(true)}
        >
          Send invitation mail
          <Icon sx={{ ml: 1 }}>mail</Icon>
        </Button>

        <Button
          color="error"
          variant="contained"
          onClick={() => setShowDeleteModal(true)}
        >
          Delete session
          <Icon sx={{ ml: 1 }}>delete</Icon>
        </Button>
      </Stack>
    </CenteredContent>
  );
};
