import React, { Dispatch, ReactNode, SetStateAction, useState } from 'react';
import { AuthContext } from '../apps/App';

interface Props {
  children: ReactNode;
}

export interface AuthContextData {
  isAuthenticated: boolean;
  setIsAuthenticated: Dispatch<SetStateAction<boolean>>;
}

function AuthProvider(props: Props) {
  const hasToken = !!sessionStorage.getItem('token');
  const [isAuthenticated, setIsAuthenticated] = useState(hasToken);

  const value = {
    isAuthenticated,
    setIsAuthenticated,
  };

  return (
    <AuthContext.Provider value={value}>{props.children}</AuthContext.Provider>
  );
}

export default AuthProvider;
