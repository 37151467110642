//@ts-ignore
import parser from 'ansi-style-parser';

type Data = {
  expected?: string;
  gotten?: string;
  output: string;
  error?: string;
};

const formatConsole = (
  output: string,
  classes: { [key: string]: string }
): string => {
  const smallerAndGreater = output
    .split('<')
    .join('&lt;')
    .split('>')
    .join('&gt;');

  const parsed = parser(smallerAndGreater)
    .map((part: { styles: string[]; text: string }) => {
      const classNames = part.styles.map((style) => classes[style]).join(' ');
      return `<span class="${classNames}">${part.text}</span>`;
    })
    .join('');

  return parsed;
};

const formatError = (
  error: string,
  classes: { [key: string]: string }
): string => {
  return formatConsole(error, classes);
};

export const formatOutput = (
  data: Data,
  classes: { [key: string]: string }
): string => {
  const { output, error } = data;

  const formattedOutput = formatConsole(output, classes);
  const formattedError = error ? formatError(error, classes) : null;

  return [formattedError, formattedOutput].filter(Boolean).join('\n');
};
