import React from 'react';
import CloudDone from '@mui/icons-material/CloudDone';
import CloudOff from '@mui/icons-material/CloudOff';
import classes from './ConnectionStatus.module.css';

export type ConnectionStatusProps = {
  connected: boolean;
};

export const ConnectionStatus: React.FC<ConnectionStatusProps> = ({
  connected,
}) => {
  return connected ? (
    <div className={classes.wrapper}>
      <CloudDone /> <span className={classes.text}>Connected</span>
    </div>
  ) : (
    <div className={classes.wrapper}>
      <CloudOff /> <span className={classes.text}>Connection error</span>
    </div>
  );
};
