import { Box } from '@mui/material';
import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { Event } from '../../interfaces/Event';
import { Controls } from './components/Controls';
import { ProgressBar } from './components/ProgressBar';

export type RecordingTimelineProps = {
  events: Event[];
  onFrameChange: (frame: number) => void;
};

export const formatTimestamp = (timestamp: number): string => {
  const seconds = Math.floor((timestamp / 1000) % 60);
  const minutes = Math.floor((timestamp / (1000 * 60)) % 60);
  const hours = Math.floor((timestamp / (1000 * 60 * 60)) % 24);

  const paddedHours = hours.toString().padStart(2, '0');
  const paddedMinutes = minutes.toString().padStart(2, '0');
  const paddedSeconds = seconds.toString().padStart(2, '0');

  return hours > 0
    ? `${paddedHours}:${paddedMinutes}:${paddedSeconds}`
    : `${paddedMinutes}:${paddedSeconds}`;
};

const SKIP_SECONDS = 5 * 1000;

export const RecordingTimeline: React.FC<RecordingTimelineProps> = ({
  events,
  onFrameChange,
}) => {
  const [currentFrame, setCurrentFrame] = useState(0);
  const [currentTime, setCurrentTime] = useState(0);
  const [isPlaying, setIsPlaying] = useState(false);
  const [playSpeed, setPlaySpeed] = useState(1);

  const processedEvents = useMemo(() => {
    const startTime = new Date(events[0].timestamp);

    return events.reduce<Event[]>((acc, item) => {
      const processedItem = {
        ...item,
        timestamp: (
          new Date(item.timestamp).getTime() - startTime.getTime()
        ).toString(),
      };

      return [...acc, processedItem];
    }, []);
  }, [events]);

  const duration = useMemo(() => {
    const startTime = new Date(events[0].timestamp);
    const endTime = new Date(events[events.length - 1].timestamp);

    return endTime.getTime() - startTime.getTime();
  }, [events]);

  useEffect(() => {
    onFrameChange(currentFrame);
  }, [currentFrame]);

  useEffect(() => {
    if (isPlaying) {
      const startTime = Date.now();
      const timeoutId = setTimeout(() => {
        const endTime = Date.now();
        const elapsed = endTime - startTime;
        const newTime = currentTime + elapsed * playSpeed;
        setCurrentTime(newTime);
      }, 33);

      return () => clearTimeout(timeoutId);
    }
  }, [isPlaying, currentTime]);

  useEffect(() => {
    const newFrameIndex = processedEvents.reduce<number>((acc, item, index) => {
      return currentTime > parseInt(item.timestamp, 10) ? index : acc;
    }, 0);

    if (currentFrame !== newFrameIndex) {
      setCurrentFrame(newFrameIndex);
    }
  }, [currentTime]);

  const onKeyPress = useCallback(
    (e: KeyboardEvent) => {
      if (e.key === ' ') {
        setIsPlaying(!isPlaying);
      } else if (e.key === 'l') {
        const nextTime = currentTime + SKIP_SECONDS;
        setCurrentTime(nextTime > duration ? duration : nextTime);
      } else if (e.key === 'h') {
        const nextTime = currentTime - SKIP_SECONDS;
        setCurrentTime(nextTime < 0 ? 0 : nextTime);
      }
    },
    [isPlaying, currentTime]
  );

  useEffect(() => {
    document.addEventListener('keydown', onKeyPress);
    return () => document.removeEventListener('keydown', onKeyPress);
  }, [isPlaying, currentTime]);

  return (
    <Box
      sx={{
        display: 'flex',
        flexDirection: 'column',
        px: 4,
        pt: 1,
      }}
    >
      <Box
        sx={{
          display: 'flex',
          flexDirection: 'row',
          alignItems: 'center',
        }}
      >
        <Controls
          isPlaying={isPlaying}
          speed={playSpeed}
          onPause={() => setIsPlaying(false)}
          onPlay={() => setIsPlaying(true)}
          onSpeedChange={(value) => setPlaySpeed(value)}
        />

        <ProgressBar
          duration={duration}
          value={currentTime}
          onChange={(value) => setCurrentTime(value)}
          events={events}
        />
      </Box>

      <Box
        sx={{
          display: 'flex',
          justifyContent: 'space-between',
          marginLeft: '4px',
          fontSize: '12px',
        }}
      >
        <Box>
          <Box sx={{ display: 'flex', alignItems: 'center' }}>
            <Box
              sx={{
                backgroundColor: 'yellow',
                width: '8px',
                height: '8px',
                mr: 1,
              }}
            ></Box>
            <Box sx={{ color: 'white' }}>Window not focused</Box>
          </Box>
          <Box sx={{ display: 'flex', alignItems: 'center' }}>
            <Box
              sx={{
                backgroundColor: 'lightgray',
                width: '8px',
                height: '8px',
                mr: 1,
              }}
            ></Box>
            <Box sx={{ color: 'white' }}>No activity</Box>
          </Box>
          <Box sx={{ display: 'flex', alignItems: 'center' }}>
            <Box
              sx={{
                backgroundColor: 'red',
                width: '8px',
                height: '8px',
                mr: 1,
              }}
            ></Box>
            <Box sx={{ color: 'white' }}>Paste</Box>
          </Box>
        </Box>

        <span>{formatTimestamp(duration)}</span>
      </Box>
    </Box>
  );
};
