import { Typography } from '@mui/material';
import Icon from '@mui/material/Icon/Icon';
import axios from 'axios';
import React, { FormEvent, useContext, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { AuthContext } from '../apps/App';
import { Button } from '../components/Button/Button';
import CenteredContent from '../components/CenteredContent';
import { TextField } from '../components/TextField/TextField';
import { API_ADDRESS } from '../constants/path';
import classes from './ReviewerAuthPage.module.css';

export const ReviewerAuthPage = () => {
  const [password, setPassword] = useState('');
  const [loginError, setLoginError] = useState<boolean>(false);
  const { setIsAuthenticated } = useContext(AuthContext);
  const navigate = useNavigate();

  const handleSubmit = async (event: FormEvent<HTMLFormElement>) => {
    event.preventDefault();
    const { data } = await axios.post(`${API_ADDRESS}/api/auth/login`, {
      password,
    });

    if (!data.ok) {
      setLoginError(true);
    } else {
      setIsAuthenticated(true);
      sessionStorage.setItem('token', data.token);
      navigate('sessions');
    }
  };

  function onChangePassword(value: string) {
    if (loginError) {
      setLoginError(false);
    }
    setPassword(value);
  }

  return (
    <CenteredContent>
      <h1>Welcome reviewer</h1>

      <p>Please enter your reviewer password below.</p>

      <form onSubmit={handleSubmit} className={classes.form}>
        <TextField
          type="password"
          name="password"
          label="Password"
          variant="filled"
          autoComplete="current-password"
          value={password}
          autoFocus
          onChange={(event) => onChangePassword(event.target.value)}
          sx={{ mb: 2 }}
        />

        <div className={classes.button}>
          <Button type="submit" size="medium">
            Submit &nbsp;<Icon>play_circle_filled</Icon>
          </Button>
        </div>
      </form>

      {loginError && (
        <Typography variant="body1" color="error">
          Incorrect password
        </Typography>
      )}
    </CenteredContent>
  );
};
