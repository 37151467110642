import axios from 'axios';
import { API_ADDRESS } from '../constants/path';

const api = axios.create({
  baseURL: API_ADDRESS,
});

api.interceptors.request.use(async (config) => {
  const token = sessionStorage.getItem('token');
  if (token) {
    config.headers = { ...config.headers };
    config.headers['Authorization'] = `Bearer ${token}`;
  }
  return config;
});

export default api;
