import React from 'react';
import { Session } from '../../interfaces/Session';
import classes from './SessionList.module.css';
import { SessionListItem } from './SessionListItem';

export type SessionListProps = {
  sessions: Session[];
};

export const SessionList: React.FC<SessionListProps> = ({ sessions }) => {
  return (
    <ul className={classes.list}>
      {sessions.map((session) => (
        <li key={session.id} className={classes.item}>
          <SessionListItem session={session} />
        </li>
      ))}
    </ul>
  );
};
