import { useContext } from 'react';
import { SocketContext } from '../context/socketContext';
import { EditorState } from '../context/editorContext';

export type UseSocket = {
  subscribe: (event: string, handler: (data: any) => void) => () => void;
  socket: SocketIOClient.Socket;
  connect: () => void;

  newSession: (sessionId: string) => void;
  sendSessionState: (state: EditorState) => void;
};

export const useSocket = (): UseSocket => {
  const { socket, setConnected, sessionId, challengeId } =
    useContext(SocketContext);

  const subscribe: UseSocket['subscribe'] = (event, handler) => {
    socket.on(event, handler);
    return () => socket.off(event, handler);
  };

  const connect = () => {
    const unsubscribe = subscribe('connect', () => {
      setConnected(true);
      unsubscribe();
    });

    socket.connect();
  };

  const newSession: UseSocket['newSession'] = (sessionId: string) => {
    socket.emit('newSession', sessionId);
  };

  const sendSessionState: UseSocket['sendSessionState'] = (
    editorState: EditorState
  ) => {
    socket.emit('sendState', sessionId, challengeId, editorState);
  };

  return {
    socket,
    subscribe,
    connect,
    sendSessionState,
    newSession,
  };
};
