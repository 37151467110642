import { useSnackbar } from 'notistack';
import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import CenteredContent from '../components/CenteredContent';
import SessionForm from '../components/SessionForm';
import api from '../utils/api';

function NewSession() {
  const [submitting, setSubmitting] = useState(false);
  const navigate = useNavigate();
  const { enqueueSnackbar } = useSnackbar();

  async function onSubmit(firstName: string, lastName: string, email: string) {
    setSubmitting(true);
    try {
      await api.post('/api/sessions', { firstName, lastName, email });
      enqueueSnackbar('Session created', { variant: 'success' });
      navigate('../sessions');
    } catch (err) {
      console.error(err);
    } finally {
      setSubmitting(false);
    }
  }

  return (
    <CenteredContent>
      <h2>New session</h2>

      <SessionForm
        cancelRoute="../sessions"
        submitting={submitting}
        onSubmit={onSubmit}
      />
    </CenteredContent>
  );
}

export default NewSession;
