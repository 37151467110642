import { Icon } from '@mui/material';
import dayjs from 'dayjs';
import React from 'react';
import { Link } from 'react-router-dom';
import { dateFormat } from '../../constants/dates';
import { Challenge } from '../../interfaces/Challenge';
import classes from './SessionDetailItem.module.css';

interface Props {
  challenge: Challenge;
}

export const SessionDetailItem: React.FC<Props> = ({ challenge }) => {
  const startDate = dayjs(challenge.events[0].timestamp);
  const endDate = dayjs(
    challenge.events[challenge.events.length - 1].timestamp
  );
  const durationInMinutes = endDate.diff(startDate, 'minutes');

  const windowSwitches = challenge.events.filter(
    (event) => !event.state.windowActive
  ).length;

  const eventCount = challenge.events.length;

  return (
    <Link className={classes.wrapper} to={`challenges/${challenge.id}`}>
      <div className={classes.header}>
        <h2>{challenge.name}</h2>
        <span>{challenge.language}</span>
      </div>

      <p className={classes.info}>
        <b>Started:</b> {dayjs(challenge.createdAt).format(dateFormat)}
      </p>

      <p className={`${classes.info} ${classes.centered}`}>
        <b>Submitted: </b>
        {challenge.completedAt ? (
          <Icon sx={{ ml: 1 }}>check</Icon>
        ) : (
          <Icon>close</Icon>
        )}
      </p>

      <p className={classes.info}>
        <b>Duration:</b> {durationInMinutes + ' minutes'}
      </p>

      <p className={classes.info}>
        <b>Events:</b> {eventCount}
      </p>

      <p className={classes.info}>
        <b>Window switches:</b> {windowSwitches}
      </p>

      {challenge.remark && (
        <p className={classes.info}>
          <b>Remark:</b>{' '}
          <span className={classes.remark}>{challenge.remark}</span>
        </p>
      )}
    </Link>
  );
};
