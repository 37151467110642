import React, { useContext } from 'react';
import { Navigate } from 'react-router-dom';
import { AuthContext } from '../apps/App';

interface Props {
  children: JSX.Element;
}

function ProtectedRoute({ children }: Props): JSX.Element {
  const { isAuthenticated } = useContext(AuthContext);

  if (!isAuthenticated) {
    return <Navigate to="/reviewer" />;
  }

  return children;
}

export default ProtectedRoute;
