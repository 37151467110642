import { useSnackbar } from 'notistack';
import React, { useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import CenteredContent from '../components/CenteredContent';
import { Session } from '../interfaces/Session';
import api from '../utils/api';
import Loader from './Loader/Loader';
import SessionForm from './SessionForm';

function EditSession() {
  const [submitting, setSubmitting] = useState(false);
  const navigate = useNavigate();
  const { sessionId } = useParams() as { sessionId: string };
  const [session, setSession] = useState<Session | undefined>(undefined);
  const { enqueueSnackbar } = useSnackbar();

  useEffect(() => {
    const fetchSession = async () => {
      const { data } = await api.get(`/api/sessions/${sessionId}`);
      setSession(data);
    };
    fetchSession();
  }, []);

  async function onSubmit(firstName: string, lastName: string, email: string) {
    setSubmitting(true);
    try {
      await api.patch(`/api/sessions/${sessionId}`, {
        firstName,
        lastName,
        email,
      });
      enqueueSnackbar('Session updated', { variant: 'info' });
      navigate(`../sessions/${sessionId}`);
    } catch (err) {
      console.error(err);
      setSubmitting(false);
    }
  }

  if (!session) {
    return <Loader />;
  }

  return (
    <CenteredContent>
      <h2>Edit session</h2>

      <SessionForm
        submitting={submitting}
        onSubmit={onSubmit}
        initialValues={{
          firstName: session.firstName,
          lastName: session.lastName,
          email: session.email,
        }}
        cancelRoute={`../sessions/${sessionId}`}
      />
    </CenteredContent>
  );
}

export default EditSession;
