import { Box } from '@mui/material';
import Slider from '@mui/material/Slider';
import { withStyles } from '@mui/styles';
import React, { useMemo } from 'react';
import { Event } from '../../../interfaces/Event';
import { IdleEvent, Mark } from '../../../interfaces/ReviewEvent';
import { formatTimestamp } from '../RecordingTimeline';

export type ProgressBarProps = {
  duration: number;
  value: number;
  onChange: (value: number) => void;
  events: Event[];
};

function transformEvents(events: Event[]): {
  idleEvents: IdleEvent[];
  marks: Mark[];
} {
  if (events.length === 1) {
    return {
      idleEvents: [],
      marks: [],
    };
  }

  const idleEvents: IdleEvent[] = [];
  const marks: Mark[] = [];

  const firstEvent = events[0];
  const lastEvent = events[events.length - 1];
  const firstEventTime = new Date(firstEvent.timestamp).getTime();
  const lastEventTime = new Date(lastEvent.timestamp).getTime();
  const duration = lastEventTime - firstEventTime;

  events.forEach((event, index) => {
    if (index === 0) {
      return;
    }

    const previousEvent = events[index - 1];
    const startTime = new Date(previousEvent.timestamp).getTime();
    const endTime = new Date(event.timestamp).getTime();
    const timeBetweenEvents = endTime - startTime;

    if (
      (timeBetweenEvents > 10000 && previousEvent.state.windowActive) ||
      !previousEvent.state.windowActive
    ) {
      const firstEventOffset = startTime - firstEventTime;
      const lastEventOffset = endTime - firstEventTime;
      const idleEvent: IdleEvent = {
        startPercentage: (firstEventOffset / duration) * 100,
        endPercentage: (lastEventOffset / duration) * 100,
        focus: previousEvent.state.windowActive,
      };
      idleEvents.push(idleEvent);
    }

    if (event.state.code.length - previousEvent.state.code.length > 5) {
      const pasteEvent: Mark = { value: endTime - firstEventTime };
      marks.push(pasteEvent);
    }
  });

  return {
    idleEvents,
    marks,
  };
}

const getGradientClass = (idleEvents: IdleEvent[]): string => {
  if (idleEvents.length === 0) {
    return 'linear-gradient(to right, black 0% 100%)';
  }

  const percentages: string[] = [];
  idleEvents.forEach((event, index) => {
    const color = event.focus ? 'lightgray' : 'yellow';
    percentages.push(
      `${color} ${event.startPercentage}% ${event.endPercentage}%`
    );
    if (index !== idleEvents.length - 1) {
      const nextEvent = idleEvents[index + 1];
      percentages.push(
        `black ${event.endPercentage}% ${nextEvent.startPercentage}%`
      );
    }
  });

  const percentageString = percentages.join(', ');

  return `linear-gradient(to right, black 0% ${idleEvents[0].startPercentage
    }%, ${percentageString}, black ${idleEvents[idleEvents.length - 1].endPercentage
    }% 100%)`;
};

const ProgressSlider = withStyles({
  root: {
    color: '#101010',
    height: 8,
  },
  thumb: {
    height: 16,
    width: 16,
    backgroundColor: '#fff',
    '&:focus, &:hover, &$active': {
      boxShadow: 'inherit',
    },
  },
  valueLabel: {
    left: 'calc(-50% - 12px)',
    fontSize: '0.6em',
  },
  track: {
    height: 8,
    borderRadius: 4,
  },
  rail: {
    height: 8,
    borderRadius: 4,
    opacity: 1,
  },
  mark: {
    color: 'red',
    width: '5px',
    height: '5px',
  },
  markLabel: {
    color: 'white',
  },
})(Slider);

export const ProgressBar: React.FC<ProgressBarProps> = ({
  duration,
  value,
  onChange,
  events,
}) => {
  const { idleEvents, marks } = useMemo(
    () => transformEvents(events),
    [events]
  );
  const gradientClass = getGradientClass(idleEvents);

  return (
    <Box
      sx={{
        display: 'flex',
        flexDirection: 'column',
        flex: 1,
      }}
    >
      <ProgressSlider
        track={false}
        min={0}
        max={duration}
        value={value}
        valueLabelFormat={(value) => formatTimestamp(value)}
        valueLabelDisplay="on"
        onChange={(_, value) => {
          Array.isArray(value) ? onChange(value[0]) : onChange(value);
        }}
        sx={{
          '& .MuiSlider-rail': {
            backgroundImage: gradientClass,
          },
        }}
        marks={marks}
      />
    </Box>
  );
};
