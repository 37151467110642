import {
  Button,
  Card,
  CardActions,
  CardContent,
  TextField,
} from '@mui/material';
import React, { FormEvent, useState } from 'react';
import { Link } from 'react-router-dom';
import CenteredContent from '../components/CenteredContent';
import classes from '../pages/NewSession.module.css';

interface Props {
  onSubmit: (firstName: string, lastName: string, email: string) => void;
  submitting: boolean;
  initialValues?: {
    firstName: string;
    lastName: string;
    email: string;
  };
  cancelRoute: string;
}

function SessionForm({
  onSubmit,
  submitting,
  initialValues = { firstName: '', lastName: '', email: '' },
  cancelRoute,
}: Props) {
  const [firstName, setFirstName] = useState(initialValues.firstName);
  const [lastName, setLastName] = useState(initialValues.lastName);
  const [email, setEmail] = useState(initialValues.email);

  function submitForm(e: FormEvent<HTMLFormElement>) {
    e.preventDefault();
    onSubmit(firstName, lastName, email);
  }

  return (
    <CenteredContent>
      <Card className={classes.card}>
        <form onSubmit={submitForm}>
          <CardContent>
            <TextField
              label="First name"
              className={classes.sessionInput}
              onChange={(e) => setFirstName(e.target.value)}
              variant="standard"
              value={firstName}
            />

            <TextField
              label="Last name"
              className={classes.sessionInput}
              onChange={(e) => setLastName(e.target.value)}
              variant="standard"
              value={lastName}
            />

            <TextField
              label="Email"
              className={classes.sessionInput}
              onChange={(e) => setEmail(e.target.value)}
              type="email"
              variant="standard"
              value={email}
            />
          </CardContent>

          <CardActions>
            <Button
              variant="contained"
              disabled={
                submitting ||
                firstName.length === 0 ||
                lastName.length === 0 ||
                email.length === 0
              }
              type="submit"
              className={classes.submitBtn}
              sx={{ mr: 2 }}
            >
              Submit
            </Button>

            <Link to={cancelRoute}>
              <Button color="error">Cancel</Button>
            </Link>
          </CardActions>
        </form>
      </Card>
    </CenteredContent>
  );
}

export default SessionForm;
