import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  TextareaAutosize,
} from '@mui/material';
import React, { useState } from 'react';
import classes from './Challenge.module.css';
import { Button as AppButton } from '../Button/Button';

interface Props {
  isSubmitting: boolean;
  isOpen: boolean;
  onClose: () => void;
  onSubmit: (remark: string) => void;
}

function SubmitChallengeModal({
  isSubmitting,
  isOpen,
  onClose,
  onSubmit,
}: Props) {
  const [remark, setRemark] = useState('');

  return (
    <Dialog open={isOpen} onClose={onClose}>
      <DialogTitle>
        <Box sx={{ color: 'black' }}>Submit challenge</Box>
      </DialogTitle>
      <DialogContent>
        <DialogContentText sx={{ mb: 2 }}>
          Are you sure you want to submit your challenge? If you want, you can
          add a remark below.
        </DialogContentText>
        <TextareaAutosize
          style={{ width: '100%' }}
          minRows={3}
          onChange={(e) => setRemark(e.target.value)}
          value={remark}
        />
        <DialogActions sx={{ mt: 2 }}>
          <Button onClick={onClose} color="secondary" sx={{ mr: 2 }}>
            Close
          </Button>

          <AppButton disabled={isSubmitting} onClick={() => onSubmit(remark)}>
            Submit
          </AppButton>
        </DialogActions>
      </DialogContent>
    </Dialog>
  );
}

export default SubmitChallengeModal;
